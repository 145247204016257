<template>
    <el-card class="box-card" :shadow="shadow">
        <div slot="header" class="card-title" v-if="title">
            <div style="width: 2px;height: 16px;background: #0084FF;"></div>
            <div style="margin-left: 8px">{{title}}</div>
        </div>
        <div style="height: 16px;" v-else></div>
        <slot></slot>
    </el-card>
</template>
 
<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        shadow: {
            type: String,
            default: 'never'
        }
    },
    methods: {
    }
}
</script>
 
<style lang="less">

.box-card{
    border: none!important;
}
.box-card/deep/.el-card__body{
    padding: 16px;
}
.box-card/deep/.el-card__header{
    padding: 16px 16px 12px;
    border-bottom: none;
}
.card-title {
    display: flex;
    align-items: center;
}
</style>