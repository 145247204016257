<template>
  <section class="plr-sm">
    <m-header class="y-center ptb-xs">
      <span class="size-md bolder">市场编辑</span>
    </m-header>
    <div class="mian_cell">
      <m-card class="m-cardcell">
        <m-form :model="obj" labelWidth="70px">
          <m-form-item
              label="市场标题"
              type="input"
              :value.sync="obj.title"
              placeholder="请输入标题"
          >
          </m-form-item>
          <m-form-item label="上传封面">
            <m-uploader type="single" :file.sync="obj.coverImage"></m-uploader>
          </m-form-item>
          <m-form-item label="市场介绍">
            <!-- <rich-text-editor
                scrollEl="#single-page-con"
                v-model="obj.content"
            ></rich-text-editor> -->
            <el-input type="textarea" v-model="obj.content" placeholder="请输入市场介绍" :rows="10"></el-input>
          </m-form-item>
        </m-form>
      </m-card>

      <div class="right">
        <m-card title="其他信息" style="width: 100%">
          <m-form
              ref="formB"
              :model="obj"
          >
            <m-form-item
                label="上架"
                type="switch"
                :value.sync="obj.status"
            ></m-form-item>

            <m-form-item>
              <el-button @click="$router.push('./list')">取消</el-button>
              <el-button type="primary" @click="onSubmit">保存</el-button>
            </m-form-item>
          </m-form>
        </m-card>
      </div>
    </div>
  </section>
</template>

<script>
// import Obj from "@/classFactory/datemanagement/Teabrand.js";
import richTextEditor from "@/common/editor.vue";
import mCard from '@/common/m-card.vue'
import mFormItem from '@/common/m-form-item.vue'
import mForm from '@/common/m-form.vue'

import Market from '@/axios/api/market.js'
import MUploader from '@/common/m-uploader.vue';

export default {
  name: "teabrand-edit1",

  components: {
    richTextEditor,
    mCard,
    mFormItem,
    mForm,
    MUploader
  },
  data() {
    return {
      objId: null,
      obj: {
        title: '', // 标题
        // bannerShowFlag: false,// 是否在首页banner进行展示
        content: '', // 内容
        coverImage: '', // 封面
        seq: 0, // 排序
        status: true, // 上下架状态
      },
      isExist: false,
    };
  },
  created() {
    let query = this.$route.query
    if (query.id) {
      this.objId = query.id
      this.getOne(query.id)
    }
  },
  methods: {
    isValid(obj) {
      if (!obj.title) {
        this.$message.info("标题不能为空");
        return false;
      }
      if (!obj.coverImage) {
        this.$message.info("封面不能为空");
        return false;
      }
      if (!obj.content) {
        this.$message.info("内容不能为空");
        return false;
      }
      return true;
    },
    // 保存
    onSubmit() {
      console.log('this', this.obj);
      if (this.isValid(this.obj)) {

        if (!this.objId) {
          // 新建
          Market.createMarket({...this.obj, status: this.obj.status ? 1 : 0}).then(res => {
            this.$router.push("./list");
          })
        } else {
          // 编辑
          Market.editMarket(this.objId, {...this.obj, status: this.obj.status ? 1 : 0}).then(res => {
            this.$router.push("./list");
          })
        }
      }
    },
    deleteImg(index) {
      const that = this;
      that.$Modal.confirm({
        title: "提示",
        content: "是否删除，请确认",
        onOk: () => {
          // that.imgList.splice(index, 1);
          that.obj.imgUrl = "";
        },
      });
    },
    // uploadSuccess(res) {
    //   this.imgList.push({ url: res.data });
    // },
    uploadSubImg(res) {
      this.obj.coverImage = res.data;
      this.$forceUpdate();
    },
    async getOne(id) {
      let res = await Market.getDetail({id})
      if (res.status == 1) {
        this.obj = res.data
        this.obj.status = this.obj.status == 1;
      }

    },
    async checkName() {
      if (this.objId) {
        return;
      }
      let res = await Obj.prototype
          .checkOutTeaBrandRepeat({name: this.obj.name})
          .catch((err) => {
          });
      if (!res) {
        this.isExist = true;
      } else {
        this.isExist = false;
      }
    },
  },
};
</script>


<style scoped>
.photo-img-row {
  flex-wrap: wrap;
}

.photo-img {
  width: 200px;
  height: 100px;
  margin-right: 15px;
  margin-bottom: 15px;
  object-fit: cover;
}

.photo-img__delete {
  font-size: 32px;
  position: absolute;
  right: 15px;
  top: 0;
  color: rgba(255, 0, 0, 0.8);
}
</style>

<style scoped>
.uploader {
  width: 150px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.uploader.sub {
  width: 150px;
}

.uploader:hover {
  border-color: #409eff;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  line-height: 150px;
  text-align: center;
}

.uploader-icon.sub {
  width: 150px;
  line-height: 150px;
}


</style>
<!-- 我新增的 -->
<style scoped lang="less">
/deep/ .el-card__body .el-form-item__label {
  width: 70px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;

}

.upload_cell {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 88px;
  box-sizing: border-box;
  width: 88px;

  .text {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    margin-top: 8px;
  }

  .el-icon-plus {
    font-size: 20px;
    color: #707070;
  }

  .subImg {
    width: 88px;
    height: 88px;
    vertical-align: middle;
  }
}

.mian_cell {
  display: flex;
  align-items: flex-start;

  .m-cardcell {
    background-color: #fff;
    flex: 1;
  }

  .right {
    width: 308px;
    height: 254px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    margin-left: 10px;
  }
}
.right .box-card {
  width: 100%;
}
</style>
