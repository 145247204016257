<template>
  <div class="m-form-item">
    <el-form-item :label="label" :prop="prop">
      <span slot="label">
        <span>{{ label }}</span>
        <el-tooltip class="item" effect="dark" :content="tip" placement="top" v-if="tip">
          <i style="color: #cccccc" class="el-icon-warning-outline"></i>
        </el-tooltip>
      </span>
      <!-- 文本 -->
      <span v-if="type === 'text'">{{ value }}</span>

      <!-- 输入框 -->
      <el-input
        :value="value"
        :placeholder="placeholder"
        @input="onInput"
        @blur="onBlur"
        class="my-input"
        size="small"
        v-if="type === 'input'"
        style="max-width: 498px"
      ></el-input>
      <!-- 密码输入框 -->
      <el-input
        type="password"
        :value="value"
        :placeholder="placeholder"
        @input="onInput"
        @blur="onBlur"
        class="my-input"
        size="small"
        v-if="type === 'password'"
        style="max-width: 498px"
      ></el-input>

      <!-- 文本框 -->
      <el-input
        type="textarea"
        :value="value"
        :placeholder="placeholder"
        @input="onInput"
        @blur="onBlur"
        class="my-input"
        size="small"
        :rows="rows || 2"
        :maxlength="maxlength"
        show-word-limit
        v-if="type === 'textarea'"
      ></el-input>

      <!-- 单项选择框 -->
      <el-select
        :value="value"
        filterable
        :placeholder="placeholder"
        clearable
        @blur="onBlur"
        @change="onSelect"
        size="small"
        class="my-select"
        v-if="type === 'select'"
        :disabled = "disabled"
      >
        <el-option
          v-for="item in options"
          :key="item[optionKeys[1]] || item.id"
          :label="item[optionKeys[0]] || item.name"
          :value="item[optionKeys[1]] || item.id"
        ></el-option>
      </el-select>

      <!-- 级联选择框 -->
      <el-cascader
        :value="cascaderValue"
        :options="options"
        :placeholder="placeholder"
        v-if="type === 'cascader'"
        @blur="onBlur"
        @change="onCascaderChange"
      ></el-cascader>

      <!-- 开关 -->
      <el-switch v-if="type === 'switch'" :value="value" @change="onSwitchChange" />

      <!-- 数字选择器 -->
      <el-input-number
        v-if="type === 'inputNumber'"
        :min="min"
        :max="max"
        :value="value"
        :precision="precision" 
        :step="step"
        :placeholder="placeholder"
        @blur="onBlur"
        size="medium"
        @change="onNumberChange"
      />

      <!-- 单选框 -->
      <template v-if="type === 'radio'">
        <el-radio
          v-for="item in options"
          :value="value"
          :key="item.id"
          :label="item[optionKeys[1]] || item.id"
          :disabled="item.disabled"
          v-bind="item.attrs"
          @input="onRadioInput"
        >
          {{ item[optionKeys[0]] || item.name }}
        </el-radio>
      </template>

      <!-- 日期选择器 -->
      <el-date-picker
        v-model="value"
        type="date"
        :value-format="valueFormat || 'timestamp'"
        :placeholder="placeholder || '选择日期'"
        v-if="type === 'date'"
      >
      </el-date-picker>

      <!-- 日期时间选择器 -->
      <el-date-picker
        v-model="value"
        type="datetime"
        :value-format="valueFormat || 'timestamp'"
        :placeholder="placeholder || '选择日期'"
        v-if="type === 'datetime'"
      >
      </el-date-picker>

      <!-- 日期时间区间选择器 -->
      <el-date-picker
        v-model="value"
        type="datetimerange"
        :default-value="new Date()"
        :value-format="valueFormat || 'timestamp'"
        :placeholder="placeholder || '选择日期'"
        v-if="type === 'datetimerange'"
        @change="onDatetimerangeChange"
      >
      </el-date-picker>

      <slot></slot>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    //左侧标签文本
    label: {
      type: String,
      default: "",
    },
    //文本域最大长度
    maxlength: {
      type: String,
      default: "",
    },
    rows: {
      type: String,
      default: '2',
    },
    //右侧绑定的值，加上.sync可以双向绑定
    value: [String, Number, Boolean],
    //表单域 model 字段，做表单校验的情况下该字段必填
    prop: [String],
    //级联选择器、日期期间选择器这些需要两个值的使用这两个
    firstValue: [String, Number],
    secondValue: [String, Number],
    //表单类型
    type: {
      type: String,
      default: "",
    },
    //选择器的禁用状态
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default() {
        return "";
      },
    },
    //选择器/单选框传入的选项
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    //展示和选中的字段配置
    optionKeys: {
      type: Array,
      default() {
        return ["label", "value"];
      },
    },
    //时间选择器指定返回值的类型
    valueFormat: {
      type: String,
      default() {
        return "";
      },
    },
    //最小值
    min: Number,
    precision:Number,
    step:Number,
    //最大值
    max: Number,
    //左侧标签文本的icon,鼠标浮上面出现的提示语
    tip: String,
  },
  computed: {
    cascaderValue() {
      return [this.firstValue, this.secondValue].filter((item) => item);
    },
  },
  methods: {
    onInput(e) {
      this.$emit("update:value", e);
    },
    onSelect(e) {
      this.$emit("update:value", e);
      this.$emit("change", e);
    },
    onSwitchChange(e) {
      this.$emit("update:value", e);
      this.$emit("change", e);
    },
    onNumberChange(cur) {
      this.$emit("update:value", cur);
    },
    onRodioChange(e) {
      console.log(e);
      this.$emit("update:value", e);
    },
    onRadioInput(e) {
      console.log(e);
      this.$emit("update:value", e);
    },
    onBlur(e) {
      this.$emit("blur", e);
    },
    onCascaderChange(list) {
      console.log(list);
      if (list.length === 2) {
        this.$emit("update:firstValue", list[0]);
        this.$emit("update:secondValue", list[1]);
      } else if (list.length === 1) {
        this.$emit("update:firstValue", list[0]);
      }
    },
    onDatetimerangeChange(list) {
      list = list || ["", ""];
      this.$emit("update:value", list);
      this.$emit("update:firstValue", list[0]);
      this.$emit("update:secondValue", list[1]);
    },
  },
};
</script>

<style>
.my-select {
  width: 100%;
  max-width: 498px;
  height: 32px;
  line-height: 32rpx;
}

.m-form-item /deep/.el-input-number--medium {
  width: 100%;
  max-width: 200px;
}

.m-form-item /deep/.el-input {
  max-width: 498px;
}

.m-form-item /deep/.el-form-item__label {
  color: #333;
}
/* 
// .my-input /deep/.el-input__inner {
//   width: 242px;
//   height: 32px !important;
//   line-height: 32px;
// }  */
</style>
