import Axios from '../axios.js';

// 新建市场
export const createMarket = (data) => {
    return Axios({
        url: `/api/admin/market`,
        method: 'post',
        // isFormData: true,
        data
    }).then(
        res => Promise.resolve(res),
        err => Promise.reject(err)
    );
}

// 获取市场列表
export const getMarketList = (params) => {
    return Axios({
        url: `/api/admin/market/postList`,
        method: 'get',
        params
    }).then(
        res => Promise.resolve(res.data),
        err => Promise.reject(err)
    );
}

// 修改状态
export const editStatus = (params) => {
    return Axios({
        url: `/api/admin/market/status/${params.id}`,
        method: 'put',
        params
    }).then(
        res => Promise.resolve(res.data),
        err => Promise.reject(err)
    );
}

// 获取市场详情
export const getDetail = (params) => {
    return Axios({
        url: `/api/admin/market/${params.id}`,
        method: 'get',
        params
    }).then(
        res => Promise.resolve(res.data),
        err => Promise.reject(err)
    );
}

// 修改市场
export const editMarket = (id, data) => {
    return Axios({
        url: `/api/admin/market/${id}`,
        method: 'put',
        data
    }).then(
        res => Promise.resolve(res),
        err => Promise.reject(err)
    );
}

// 删除市场
export const deleteMarket = (id) => {
    return Axios({
        url: `/api/admin/market/${id}`,
        method: 'delete',
    }).then(
        res => Promise.resolve(res.data),
        err => Promise.reject(err)
    );
}

// 在下面写就包含对象引入，以上属于可以解构的
export default {
    createMarket,
    getMarketList,
    editStatus,
    getDetail,
    editMarket,
    deleteMarket,
}
