<template>
    <el-form :ref="refName" :model="model" :label-width="labelWidth" :rules="rules">
        <slot></slot>
        <el-form-item v-if="showButton">
            <el-button type="primary" @click="onSubmit">{{submitText}}</el-button>
        </el-form-item>
    </el-form>
</template>
 
<script>
export default {
    props: {
        refName: {
            type: String,
            default: 'form'
        },
        //表单绑定数据对象
        model: {
            type: Object,
            default: () => {
                return {}
            }
        },
        //表单校验规则
        rules: {
            type: Object,
            default: () => {
                return {}
            }
        },
        //是否展示表单按钮
        showButton: {
            type: Boolean,
            default: false
        },
        //按钮文字
        submitText: {
            type: String,
            default: '提交'
        },

        labelWidth: {
            type: String,
            default: '30%'
        }
    },

    methods: {
        onSubmit() {
            this.$emit('submit')
        },
        validate() {
            return new Promise((resolve, reject) => {
                this.$refs[this.refName].validate(valid => {
                    resolve(valid)
                })
            })
        },
        clearValidate(prop) {
            this.$refs[this.refName].validate(prop);
        }
    }
}
</script>
 
<style>

</style>